import api from '../api/index';

const prefix = 'checklist';
const getChecklistList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );
const getChecklistDetails = (id) => api.get(`${prefix}/${id}`);
const updateChecklistStatus = (id, data) =>
  api.put(`${prefix}/${id}/status`, data);

export { getChecklistList, getChecklistDetails, updateChecklistStatus };
