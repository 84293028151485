/* eslint-disable prettier/prettier */
import { defaultDirection } from '../constants/defaultValues';
import { NotificationManager } from '../components/common/react-notifications';
import { utilityService } from 'src/services';

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem('direction')) {
    const localValue = localStorage.getItem('direction');
    if (localValue === 'rtl' || localValue === 'ltr') {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};

export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  localStorage.setItem('direction', direction);
};

export const formatPhone = (valor) => {
  if (valor === null) return '';

  if (valor.length === 10)
    return valor.replace(/(\d{2})(\d{4})(\d{4})/g, '($1) $2-$3');

  if (valor.length === 11)
    return valor.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/g, '($1) $2 $3-$4');

  if (valor.length == 14)
    return valor.replace(/(\d{2})(\d{2})(\d{5})(\d{3})/g, '$1 ($2) $3-$4');

  return valor;
};

export const formatZipcode = (valor) => {
  if (valor.length === 8) return valor.replace(/(\d{5})(\d{3})/g, '$1-$2');
  return valor;
};

export const formatTaxId = (valor) => {
  if (valor.length === 11)
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  return valor.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    '$1.$2.$3/$4-$5',
  );
};

export const validateCPF = (cpf) => {
  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i += 1)
    soma += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10), 10)) return false;
  soma = 0;
  for (let x = 1; x <= 10; x += 1)
    soma += parseInt(cpf.substring(x - 1, x), 10) * (12 - x);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11), 10)) return false;
  return true;
};

export const processErrorApi = (err, setFieldError, callback) => {
  let msg = err.message;

  let params = null;

  if (err.response && err.response.data) {
    if (
      err.response.status === 422 &&
      Object.prototype.hasOwnProperty.call(err.response.data, 'errors')
    ) {
      msg = '';
      params = err.response.data.errors;

      if (setFieldError) {
        Object.keys(err.response.data.errors).forEach((key) => {
          const val = err.response.data.errors[key];
          let fieldError = '';
          if (Array.isArray(val)) {
            val.forEach((itemError) => {
              if (typeof itemError === 'string') {
                fieldError += `${itemError}\r\n`;
              }
            });
          }
          setFieldError(key, fieldError);
        });
      }

      Object.values(err.response.data.errors).forEach((val) => {
        if (Array.isArray(val)) {
          val.forEach((itemError) => {
            if (typeof itemError === 'string') {
              msg += `${itemError}\r\n`;
            }
          });
        }
      });
    } else if (
      Object.prototype.hasOwnProperty.call(err.response.data, 'message')
    ) {
      msg = err.response.data.message;
    }
  }

  NotificationManager.error(
    msg,
    'Ocorreu um erro!',
    3000,
    null,
    null,
    'filled',
  );

  if (callback) {
    callback(params);
  }
};

export const hexToRGBA = (hexCode, opacity = 1) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};

export const getColorForStatus = (status, isHexa) => {
  let color = '';

  switch (status) {
    case 'PLANNED':
      color = 'secondary';
      break;
    case 'RUNNING':
    case 'ENDED_RUN':
      color = 'info';
      break;
    case 'BILLED':
      color = 'dark';
      break;
    case 'FINALIZED':
    case 'APPROVED':
    case 'ACTIVE':
    case 'ACCEPTED':
    case 'COMPLETED':
    case 1:
    case true:
      color = 'success';
      break;
    case 'PENDING_ACCEPT':
      color = 'warning';
      break;
    case 'ERROR':
    case 'CANCELED':
    case 'INACTIVE':
    case 'REFUSED':
    case 0:
    case false:
      color = 'danger';
      break;
    default:
      color = 'light';
  }

  if (isHexa) {
    switch (color) {
      case 'info':
        color = '#72afff';
        break;
      case 'dark':
        color = '#131113';
        break;
      case 'success':
        color = '#3e884f';
        break;
      case 'warning':
        color = '#b69329';
        break;
      case 'danger':
        color = '#c43d4b';
        break;
      default:
        color = '#ececec';
    }
  }

  return color;
};

export const getColorForStage = (status) => {
  let color = '';

  switch (status) {
    case 'ON_THE_WAY':
      color = '#999999';
      break;
    case 'WAITING_PASSENGER':
      color = '#b69329';
      break;
    case 'EMBARKED':
      color = '#279af1';
      break;
    case 'DISEMBARKED':
      color = '#34ad3c';
      break;
    case 'CANCELED':
      color = '#c43d4b';
      break;
    default:
      color = '#CCCCCC';
  }

  return color;
};

export const checkPermission = (needed, stack) => {
  return stack?.some((p) => p.slug === needed) ?? false;
};

export const array_move = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k >= 0) {
      k -= 1;
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
};

export const maskMobileBrasil = [
  '+',
  '5',
  '5',
  ' ',
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const maskMobileWorld = [
  '+',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const copyToClipboard = async (string) => {
  navigator.permissions
    .query({ name: 'clipboard-write' })
    .then(async (result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        try {
          await navigator.clipboard.writeText(string);
          NotificationManager.success('Copiado para área de transferência');
        } catch (err) {
          console.error('Failed to copy: ', err);
        }
      }
    });
};

export const getCities = async (stateId, prepareSelect = false) => {
  return new Promise((resolve, reject) => {
    utilityService
      .cities(stateId)
      .then(({ data }) => {
        if (!prepareSelect) {
          resolve(data);
          return;
        }
        resolve(
          data.data.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          }),
        );
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getStates = async (prepareSelect = false) => {
  return new Promise((resolve, reject) => {
    utilityService
      .states()
      .then(({ data }) => {
        if (!prepareSelect) {
          resolve(data);
          return;
        }
        resolve(
          data.data.map((item) => {
            return {
              value: item.id,
              label: item.abbr,
            };
          }),
        );
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const dateWithoutTimezone = (date) => {
  const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
  const withoutTimezone = new Date(date.valueOf() - tzoffset)
    .toISOString()
    .slice(0, -1);
  return withoutTimezone;
};

export const getFullAddressString = (address) => {
  let adr = `${address.address}, ${address.number}`;
  if (address.complement) adr = `${adr}, ${address.complement}`;
  adr = `${adr}, ${address.neighborhood}, ${address.city.name} - ${
    address.city.state.abbr
  } ${formatZipcode(address.zipcode)}`;

  return adr;
};

export const getWhatsAppLink = (phone) => {
  let phoneStr = phone?.replace(/\D/g, '') ?? '';
  if (phoneStr.length === 10) {
    phoneStr = `55${phoneStr}`;
  }
  return `https://wa.me/${phoneStr}`;
};

export const notifyUserBrowser = (message) => {
  if (!('Notification' in window)) {
    console.log('This browser does not support desktop notification');
  } else if (Notification.permission === 'granted') {
    new Notification(message);
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(function (permission) {
      if (permission === 'granted') {
        new Notification(message);
      }
    });
  }
};
