import {
  getRepairOrderList,
  getRepairOrderDetails,
  updateRepairOrderStatus,
  postRepairOrderResolveItems,
} from '../api/repairOrder';

const list = (params) => getRepairOrderList(params);
const show = (id) => getRepairOrderDetails(id);
const updateStatus = (id, data) => updateRepairOrderStatus(id, data);
const resolveItems = (id, params) => postRepairOrderResolveItems(id, params);

export const repairOrderService = {
  list,
  show,
  updateStatus,
  resolveItems,
};
