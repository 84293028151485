import {
  getChecklistDetails,
  getChecklistList,
  updateChecklistStatus,
} from '../api/checklist';

const list = (params) => getChecklistList(params);
const show = (id) => getChecklistDetails(id);
const updateStatus = (id, data) => updateChecklistStatus(id, data);

export const checklistService = {
  list,
  show,
  updateStatus,
};
