import api from './index';

const prefix = 'repair-order';
const getRepairOrderList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );
const getRepairOrderDetails = (id) => api.get(`${prefix}/${id}`);
const updateRepairOrderStatus = (id, data) =>
  api.put(`${prefix}/${id}/status`, data);
const postRepairOrderResolveItems = (id, params) =>
  api.post(`${prefix}/${id}/resolve-items`, params);

export {
  getRepairOrderList,
  getRepairOrderDetails,
  updateRepairOrderStatus,
  postRepairOrderResolveItems,
};
